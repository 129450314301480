exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-case-studies-laegern-tsx": () => import("./../../../src/pages/case-studies/laegern.tsx" /* webpackChunkName: "component---src-pages-case-studies-laegern-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-case-studies-zeitlupe-tsx": () => import("./../../../src/pages/case-studies/zeitlupe.tsx" /* webpackChunkName: "component---src-pages-case-studies-zeitlupe-tsx" */),
  "component---src-pages-community-guidelines-tsx": () => import("./../../../src/pages/community-guidelines.tsx" /* webpackChunkName: "component---src-pages-community-guidelines-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-methodology-tsx": () => import("./../../../src/pages/methodology.tsx" /* webpackChunkName: "component---src-pages-methodology-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-solutions-clients-plus-tsx": () => import("./../../../src/pages/solutions/clients-plus.tsx" /* webpackChunkName: "component---src-pages-solutions-clients-plus-tsx" */),
  "component---src-pages-solutions-culture-plus-tsx": () => import("./../../../src/pages/solutions/culture-plus.tsx" /* webpackChunkName: "component---src-pages-solutions-culture-plus-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-pages-solutions-leadership-plus-tsx": () => import("./../../../src/pages/solutions/leadership-plus.tsx" /* webpackChunkName: "component---src-pages-solutions-leadership-plus-tsx" */),
  "component---src-pages-solutions-public-plus-tsx": () => import("./../../../src/pages/solutions/public-plus.tsx" /* webpackChunkName: "component---src-pages-solutions-public-plus-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

